<template>
  <div class="iq-card p-1 text-center">
    <welcome-page welcomeMessage="welcome To User And Permission Tab"></welcome-page>
  </div>
</template>
<script>
export default {
  name: 'welcomeUser'
}
</script>
